import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div id="notfound">
      <div className="notfound">
        <div className="notfound-404">
          <h1>404</h1>
          <h2>Page not found</h2>
          <h3>You just hit a route that doesn't exist... the sadness.</h3>
        </div>
        <Link to="/">Homepage</Link>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
